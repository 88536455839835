
import { Component, Vue } from 'vue-property-decorator'
import { appName } from '@/env'
import mainModule from '@/store/main'

@Component
export default class Login extends Vue {
  public email = ''
  public password = ''
  public appName = appName

  public get loginError() {
    return mainModule.logInError
  }

  public submit() {
    mainModule.actionLogIn({
      username: this.email,
      password: this.password
    })
  }
}
